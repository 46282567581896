<template>
  <v-dialog v-if="editingVenueConnections" v-model="editedVenue.data" width="90%">
    <v-card>
      <v-card-title class="primary white--text py-2 px-4">
        {{ $t('Edit site transitions') }}
      </v-card-title>
      <div class="container">
        <v-overlay class="text-center" absolute="absolute" :value="isVenueMapLoading" opacity="0.8">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
          <div class="mt-4">{{ $t('Loading...') }}</div>
        </v-overlay>
        <venue-map @load="mapLoaded"></venue-map>
      </div>
      <div id="panel">
        <v-card>
          <venue-level-selector></venue-level-selector>
        </v-card>
      </div>
      <v-card-actions class="primary">
        <v-spacer></v-spacer>
        <gli-button @click="saveVenueConnections">{{ $t('OK') }}</gli-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 200px);
  margin: 0;
  padding: 0;
}

#panel {
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: 100px;
}
</style>

<script>
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw/index';
import venueConnectionDrawStyles from '@/helpers/map/venueConnectionDrawStyles';
import { mapActions, mapGetters } from 'vuex';
import constants from '~/shared/constants';
import GliButton from './base/GliButton.vue';
import VenueMap from './VenueMap.vue';
import { bbox as turfBbox } from '@turf/turf';
import VenueLevelSelector from './VenueLevelSelector.vue';
import SimpleSelectModeOverride from '@/helpers/map/SimpleSelectModeOverride';

export default {
  components: {
    VenueMap,
    GliButton,
    VenueLevelSelector
  },

  name: 'VenueConnectionsDialog',

  data() {
    return {
      isVenueMapLoading: true,
      map: undefined,
      draw: undefined,
      popup: new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      }),

      filteredTypes: [
        constants.FEATURE_TYPES.EDGE,
        constants.FEATURE_TYPES.NETWORK_SEGMENT,
        constants.FEATURE_TYPES.JUNCTION
      ]
    };
  },

  computed: {
    ...mapGetters('venue', [
      'isNew',
      'editedVenue',
      'editingVenueConnections',
      'onLevel',
      'onLevelIds',
      'siteToHide',
      'exchangePointToFeature',
      'getSiteNameOfFeature',
      'forceUpdateDraw',
      'isOnLevel'
    ])
  },

  async mounted() {
    this.isVenueMapLoading = true;
  },

  watch: {
    siteToHide() {
      this.loadFeatures();
    },

    forceUpdateDraw() {
      if (this.forceUpdateDraw) {
        this.loadFeatures();
      }
    },

    onLevel() {
      this.loadFeatures();
    }
  },

  methods: {
    ...mapActions('venue', [
      'saveVenueConnections',
      'changeLevel',
      'updateExchangePoint',
      'updateDrawForced'
    ]),

    mapLoaded(map) {
      this.map = map;
      this.map.getCanvas().style.cursor = 'default';
      this.isVenueMapLoading = false;

      this.draw = new MapboxDraw({
        modes: { simple_select: SimpleSelectModeOverride },
        displayControlsDefault: false,
        controls: {},
        userProperties: true,
        styles: [...venueConnectionDrawStyles]
      });
      this.map.addControl(this.draw, 'top-left');

      this.map.on('mousemove', (e) => {
        let featuresHere = this.draw.getFeatureIdsAt(e.point);
        if (featuresHere.length > 0) {
          for (let i = 0; i < featuresHere.length; i++) {
            let feature = this.draw.get(featuresHere[i]);
            if (feature && feature.geometry.type === 'Point') {
              this.map.getCanvas().style.cursor = 'pointer';
              this.popup
                .setLngLat(feature.geometry.coordinates)
                .setHTML(
                  `<div style="padding: 5px 10px"><strong>${this.getSiteNameOfFeature(
                    feature
                  )}</strong></div>`
                )
                .addTo(this.map);
              return;
            } else if (feature && feature.geometry.type === 'LineString') {
              this.map.getCanvas().style.cursor = 'pointer';
              this.popup.remove();
              return;
            }
          }
        }
        this.map.getCanvas().style.cursor = 'default';
        this.popup.remove();
      });

      this.loadFeatures();

      const bbox = this.getBbox();
      this.map.fitBounds(bbox);
    },

    loadFeatures() {
      this.draw.deleteAll();
      this.removeLayers();
      this.addDrawFeatures();
      this.addWallAndDoorLayers();
      this.addNetworkLayer();
      this.addFeaturesExcludedFromDraw();
      this.updateDrawForced();
    },

    addDrawFeatures() {
      for (const site of this.editedVenue.data.sites) {
        if (site.levels.some((level) => level.order === this.onLevel)) {
          const filteredFeatures = site.features
            .filter(
              (feature) =>
                this.onLevelIds.includes(feature.levelId) &&
                this.filteredTypes.includes(feature.feature.properties._type)
            )
            .map((feature) => {
              return { ...feature.feature, id: feature.id };
            });

          if (!this.map.getSource(`site-source-${site.id}`)) {
            this.map.addSource(`site-source-${site.id}`, {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: []
              }
            });
          }

          this.map.getSource(`site-source-${site.id}`).setData({
            type: 'FeatureCollection',
            features: filteredFeatures
          });

          // add site-transition entry points to draw
          if (!this.siteToHide || this.siteToHide !== site.id) {
            for (const feature of filteredFeatures) {
              if (
                feature.properties._type === constants.FEATURE_TYPES.JUNCTION &&
                feature.properties.type === constants.JUNCTION_TYPES.ENTRY_POINT &&
                feature.properties.siteTransition &&
                !feature.properties.used
              ) {
                this.draw.add(feature);
              }
            }
          }
        }
      }

      // add exchange points as lines to draw
      for (const exchangePoint of this.editedVenue.data.exchangePoints) {
        if (
          exchangePoint.type === constants.EXCHANGE_POINT_TYPES.SITE_TRANSITION &&
          this.isOnLevel(exchangePoint)
        ) {
          const id = this.draw.add(this.exchangePointToFeature(exchangePoint))[0];
          this.updateExchangePoint({ ...exchangePoint, id });
        }
      }
    },

    addWallAndDoorLayers() {
      for (const site of this.editedVenue.data.sites) {
        if (site.levels.some((level) => level.order === this.onLevel)) {
          this.map.addLayer({
            id: `wall-site-${site.id}`,
            source: `site-source-${site.id}`,
            type: 'line',
            filter: [
              'all',
              ['==', '$type', 'LineString'],
              ['==', '_type', constants.FEATURE_TYPES.EDGE],
              ['!=', 'type', constants.EDGE_TYPES.DOOR],
              ['!=', 'thickness', constants.EDGE_THICKNESS.LOGICAL]
            ],
            paint: {
              'line-width': constants.STYLES.VENUE_EDITOR.WALL.WIDTH,
              'line-color': constants.STYLES.VENUE_EDITOR.WALL.COLOR,
              'line-opacity': constants.STYLES.VENUE_EDITOR.WALL.OPACITY
            }
          });

          this.map.addLayer({
            id: `door-site-${site.id}`,
            source: `site-source-${site.id}`,
            type: 'line',
            filter: [
              'all',
              ['==', '$type', 'LineString'],
              ['==', '_type', constants.FEATURE_TYPES.EDGE],
              ['==', 'type', constants.EDGE_TYPES.DOOR]
            ],
            paint: {
              'line-width': constants.STYLES.VENUE_EDITOR.DOOR.WIDTH,
              'line-color': constants.STYLES.VENUE_EDITOR.DOOR.COLOR,
              'line-opacity': constants.STYLES.VENUE_EDITOR.DOOR.OPACITY
            }
          });
        }
      }
    },

    addNetworkLayer() {
      for (const site of this.editedVenue.data.sites) {
        if (site.levels.some((level) => level.order === this.onLevel)) {
          this.map.addLayer({
            id: `network-site-${site.id}`,
            source: `site-source-${site.id}`,
            type: 'line',
            filter: [
              'all',
              ['==', '$type', 'LineString'],
              ['==', '_type', constants.FEATURE_TYPES.NETWORK_SEGMENT]
            ],
            paint: {
              'line-width': constants.STYLES.VENUE_EDITOR.NETWORK.WIDTH,
              'line-color': constants.STYLES.VENUE_EDITOR.NETWORK.COLOR,
              'line-opacity': constants.STYLES.VENUE_EDITOR.NETWORK.OPACITY,
              'line-dasharray': constants.STYLES.VENUE_EDITOR.NETWORK.DASH_ARRAY
            }
          });
        }
      }
    },

    addFeaturesExcludedFromDraw() {
      for (const site of this.editedVenue.data.sites) {
        if (site.levels.some((level) => level.order === this.onLevel)) {
          if (this.siteToHide && this.siteToHide === site.id) {
            // used entry points of siteToHide except for selected
            this.map.addLayer({
              id: `entry-point-used-site-${site.id}`,
              source: `site-source-${site.id}`,
              type: 'circle',
              filter: [
                'all',
                ['==', '$type', 'Point'],
                ['==', '_type', constants.FEATURE_TYPES.JUNCTION],
                ['==', 'type', constants.JUNCTION_TYPES.ENTRY_POINT],
                ['==', 'siteTransition', true],
                ['!=', 'highlight', true],
                ['==', 'used', true]
              ],
              paint: {
                'circle-radius': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_RADIUS.USED,
                'circle-color': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_COLOR.USED,
                'circle-opacity': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_OPACITY.USED
              }
            });

            this.map.addLayer({
              id: `entry-point-used-icon-site-${site.id}`,
              source: `site-source-${site.id}`,
              type: 'symbol',
              filter: [
                'all',
                ['==', '$type', 'Point'],
                ['==', '_type', constants.FEATURE_TYPES.JUNCTION],
                ['==', 'type', constants.JUNCTION_TYPES.ENTRY_POINT],
                ['==', 'siteTransition', true],
                ['!=', 'highlight', true],
                ['==', 'used', true]
              ],
              layout: {
                'icon-image': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON,
                'icon-ignore-placement': true,
                'icon-allow-overlap': true,
                'icon-size': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON_SIZE
              },
              paint: {
                'icon-opacity': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON_OPACITY.USED
              }
            });

            // unused entry points of siteToHide
            this.map.addLayer({
              id: `entry-point-hidden-site-${site.id}`,
              source: `site-source-${site.id}`,
              type: 'circle',
              filter: [
                'all',
                ['==', '$type', 'Point'],
                ['==', '_type', constants.FEATURE_TYPES.JUNCTION],
                ['==', 'type', constants.JUNCTION_TYPES.ENTRY_POINT],
                ['==', 'siteTransition', true],
                ['!=', 'highlight', true],
                ['!=', 'used', true]
              ],
              paint: {
                'circle-radius': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_RADIUS.HIDDEN,
                'circle-color': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_COLOR.HIDDEN,
                'circle-opacity':
                  constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_OPACITY.HIDDEN
              }
            });

            this.map.addLayer({
              id: `entry-point-hidden-icon-site-${site.id}`,
              source: `site-source-${site.id}`,
              type: 'symbol',
              filter: [
                'all',
                ['==', '$type', 'Point'],
                ['==', '_type', constants.FEATURE_TYPES.JUNCTION],
                ['==', 'type', constants.JUNCTION_TYPES.ENTRY_POINT],
                ['==', 'siteTransition', true],
                ['!=', 'highlight', true],
                ['!=', 'used', true]
              ],
              layout: {
                'icon-image': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON,
                'icon-ignore-placement': true,
                'icon-allow-overlap': true,
                'icon-size': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON_SIZE
              },
              paint: {
                'icon-opacity': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON_OPACITY.HIDDEN
              }
            });

            // selected entry point
            this.map.addLayer({
              id: `entry-point-selected-site-${site.id}`,
              source: `site-source-${site.id}`,
              type: 'circle',
              filter: [
                'all',
                ['==', '$type', 'Point'],
                ['==', '_type', constants.FEATURE_TYPES.JUNCTION],
                ['==', 'type', constants.JUNCTION_TYPES.ENTRY_POINT],
                ['==', 'siteTransition', true],
                ['==', 'highlight', true]
              ],
              paint: {
                'circle-radius': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_RADIUS.ACTIVE,
                'circle-color': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_COLOR.ACTIVE,
                'circle-opacity':
                  constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_OPACITY.ACTIVE,
                'circle-blur': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_BLUR_ACTIVE
              }
            });

            this.map.addLayer({
              id: `entry-point-selected-icon-site-${site.id}`,
              source: `site-source-${site.id}`,
              type: 'symbol',
              filter: [
                'all',
                ['==', '$type', 'Point'],
                ['==', '_type', constants.FEATURE_TYPES.JUNCTION],
                ['==', 'type', constants.JUNCTION_TYPES.ENTRY_POINT],
                ['==', 'siteTransition', true],
                ['==', 'highlight', true]
              ],
              layout: {
                'icon-image': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON,
                'icon-ignore-placement': true,
                'icon-allow-overlap': true,
                'icon-size': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON_SIZE
              },
              paint: {
                'icon-opacity': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON_OPACITY.ACTIVE
              }
            });
          } else {
            // used entry points of other sites
            this.map.addLayer({
              id: `entry-point-used-site-${site.id}`,
              source: `site-source-${site.id}`,
              type: 'circle',
              filter: [
                'all',
                ['==', '$type', 'Point'],
                ['==', '_type', constants.FEATURE_TYPES.JUNCTION],
                ['==', 'type', constants.JUNCTION_TYPES.ENTRY_POINT],
                ['==', 'siteTransition', true],
                ['==', 'used', true]
              ],
              paint: {
                'circle-radius': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_RADIUS.USED,
                'circle-color': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_COLOR.USED,
                'circle-opacity': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_OPACITY.USED
              }
            });

            this.map.addLayer({
              id: `entry-point-used-icon-site-${site.id}`,
              source: `site-source-${site.id}`,
              type: 'symbol',
              filter: [
                'all',
                ['==', '$type', 'Point'],
                ['==', '_type', constants.FEATURE_TYPES.JUNCTION],
                ['==', 'type', constants.JUNCTION_TYPES.ENTRY_POINT],
                ['==', 'siteTransition', true],
                ['==', 'used', true]
              ],
              layout: {
                'icon-image': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON,
                'icon-ignore-placement': true,
                'icon-allow-overlap': true,
                'icon-size': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON_SIZE
              },
              paint: {
                'icon-opacity': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON_OPACITY.USED
              }
            });
          }
        }
      }
    },

    removeLayers() {
      for (const site of this.editedVenue.data.sites) {
        const layerIds = [
          `wall-site-${site.id}`,
          `door-site-${site.id}`,
          `network-site-${site.id}`,
          `entry-point-used-site-${site.id}`,
          `entry-point-used-icon-site-${site.id}`,
          `entry-point-hidden-site-${site.id}`,
          `entry-point-hidden-icon-site-${site.id}`,
          `entry-point-selected-site-${site.id}`,
          `entry-point-selected-icon-site-${site.id}`
        ];
        for (const layerId of layerIds) {
          if (this.map.getLayer(layerId)) {
            this.map.removeLayer(layerId);
          }
        }
      }
    },

    getBbox() {
      const features = [];
      for (const site of this.editedVenue.data.sites) {
        const filteredFeatures = site.features
          .filter((feature) => this.filteredTypes.includes(feature.feature.properties._type))
          .map((feature) => feature.feature);
        features.push(...filteredFeatures);
      }

      const bbox = turfBbox({
        type: 'FeatureCollection',
        features: features
      });
      return bbox;
    }
  }
};
</script>

<template>
  <v-select
    class="mx-3 pa-0"
    :items="levels"
    @change="changeLevel"
    hide-details
    v-model="currentLevel"
  ></v-select>
</template>

<style></style>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'LevelSelector',

  data() {
    return {};
  },
  computed: {
    ...mapGetters('venue', ['onLevel', 'levelEnds']),

    levels() {
      if (this.levelEnds) {
        const levels = [];
        for (let i = this.levelEnds.minLevel; i <= this.levelEnds.maxLevel; i++) {
          levels.push(i);
        }
        return levels;
      } else {
        return [];
      }
    },

    currentLevel: {
      get() {
        return this.onLevel;
      },
      set(newLevel) {
        this.changeLevel(newLevel);
      }
    }
  },

  methods: {
    ...mapActions('venue', ['changeLevel'])
  },
  components: {}
};
</script>

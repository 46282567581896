import constants from '~/shared/constants';

export default [
  {
    id: 'gl-draw-venue-connection-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['==', 'active', 'false'],
      ['==', 'user__type', constants.FEATURE_TYPES.VENUE_CONNECTION]
    ],
    paint: {
      'line-width': constants.STYLES.LINE.LINE_WIDTH,
      'line-color': constants.STYLES.VENUE_EDITOR.CONNECTION_COLOR_INACTIVE,
      'line-dasharray': constants.STYLES.LINE.DASH_ARRAY,
      'line-opacity': constants.STYLES.VENUE_EDITOR.CONNECTION_OPACITY_INACTIVE
    }
  },
  {
    id: `gl-draw-venue-site-transition-inactive`,
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'false'],
      ['==', 'user__type', constants.FEATURE_TYPES.JUNCTION],
      ['==', 'user_type', constants.JUNCTION_TYPES.ENTRY_POINT],
      ['==', 'user_siteTransition', true],
      ['!=', 'highlight', true]
    ],
    paint: {
      'circle-radius': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_RADIUS.INACTIVE,
      'circle-color': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_COLOR.INACTIVE,
      'circle-opacity': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.CIRCLE_OPACITY.INACTIVE
    }
  },
  {
    id: 'gl-draw-venue-site-transition-icon-inactive',
    type: 'symbol',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'false'],
      ['==', 'user__type', constants.FEATURE_TYPES.JUNCTION],
      ['==', 'user_type', constants.JUNCTION_TYPES.ENTRY_POINT],
      ['==', 'user_siteTransition', true],
      ['!=', 'highlight', true]
    ],
    layout: {
      'icon-image': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON,
      'icon-ignore-placement': true,
      'icon-allow-overlap': true,
      'icon-size': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON_SIZE
    },
    paint: {
      'icon-opacity': constants.STYLES.VENUE_EDITOR.SITE_TRANSITION.ICON_OPACITY.INACTIVE
    }
  }
];

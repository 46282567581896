<template>
  <div id="map"></div>
</template>

<style>
@import '../../node_modules/mapbox-gl/dist/mapbox-gl.css';
</style>

<script>
import mapboxgl from 'mapbox-gl';
import ApiService from '../services/ApiService';
import config from '../helpers/configProvider';

let root =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port ? ':' + window.location.port : '') +
  process.env.BASE_URL;

export default {
  name: 'VenueMap',

  data() {
    return {
      map: undefined,
      tileUrl:
        config.getEnv(config.enum.VUE_APP_TILE_URL) ||
        'https://vt-dev.mapcat.com/gli/hungary/tile/{z}/{x}/{y}.pbf'
    };
  },

  async mounted() {
    mapboxgl.accessToken = config.getEnv(config.enum.VUE_APP_MAPBOX_ACCESS_TOKEN);

    if (!mapboxgl.accessToken) {
      /* eslint-disable no-console */
      console.error('The Mapbox access token is not specified.');
    }

    let { data: mapcatJson } = await ApiService.getBaseMapStyle();

    // hide poi layers
    const poiLayerIds = ['poi-parks', 'poi-not-parks', 'poi-not-parks-label'];
    mapcatJson.layers
      .filter((layer) => poiLayerIds.includes(layer.id))
      .forEach((layer) => (layer.layout.visibility = 'none'));

    mapcatJson.sources['composite'].tiles = [this.tileUrl];
    mapcatJson.sources['composite.poi'].tiles = [this.tileUrl];
    mapcatJson.sources['composite.shape'].tiles = [this.tileUrl];
    mapcatJson.sprite = root + '/assets/sprite';

    this.map = new mapboxgl.Map({
      container: 'map',
      vector: true,
      zoom: 7,
      minZoom: 3,
      maxZoom: 26,

      center: [20.253, 47.267],
      style: mapcatJson,
      hash: true,
      attributionControl: true,
      zoomControl: true,
      dragRotate: true,
      preserveDrawingBuffer: true
    });

    let zoomControl = new mapboxgl.NavigationControl({
      position: 'bottom-right',
      visualizePitch: true
    });
    let scaleControl = new mapboxgl.ScaleControl({
      position: 'top-left',
      maxWidth: 100,
      unit: 'metric'
    });

    this.map.addControl(zoomControl, 'top-left');
    this.map.addControl(scaleControl);

    this.map.on('load', () => {
      this.$emit('load', this.map);
    });

    this.localize();
  },

  watch: {
    '$i18n.locale': {
      handler() {
        this.localize();
      }
    }
  },

  methods: {
    localize() {
      document.querySelector('button.mapboxgl-ctrl-zoom-in').title = this.$t('Zoom in');
      document.querySelector('button.mapboxgl-ctrl-zoom-out').title = this.$t('Zoom out');
      document.querySelector('button.mapboxgl-ctrl-compass').title = this.$t('Compass');
    }
  }
};
</script>
